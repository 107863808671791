<template>
  <section class="page-news bg-gray-100 min-h-screen">
    <div
      v-if="initializing"
      class="flex flex-col justify-center items-center h-screen"
    >
      <van-loading color="#0094ff">
        加载中...
      </van-loading>
    </div>
    <template v-else>
      <van-search
        v-model="form.keyword"
        show-action
        label="搜索"
        placeholder="请输入搜索关键字"
        :disabled="loading"
        @search="doSearch"
      >
        <template #action>
          <div @click="doSearch">
            搜索
          </div>
        </template>
      </van-search>
      <template v-if="loading || news.total">
        <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
        >
          <van-list
            v-model="loading"
            :finished="news.to === null || news.to == news.total"
            :finished-text="loading ? '加载中...' : '没有更多了'"
            error-text="加载失败，请下拉页面重新加载"
            :immediate-check="false"
            class="pb-12"
            @load="fetch"
          >
            <div
              v-for="item of news.data"
              :key="`item-${item._id}`"
              class="list-card bg-white border-gray-200 border-b p-3 m-3 rounded-lg"
              @click="goPage({
                name: 'public.news.get',
                params: { news: item._id }
              })"
            >
              <div class="flex flex-row justify-start items-start">
                <div
                  v-if="item.image"
                  class="thumbnail"
                >
                  <van-image
                    width="80"
                    height="80"
                    :src="`${item.image}!square_head_image`"
                  />
                </div>
                <div
                  class="content flex-1 pl-2 flex flex-col justify-between items-start"
                  :style="item.image ? 'min-height: 80px;' : ''"
                >
                  <div class="font-semibold leading-snug mb-2">
                    {{ item.title }}<van-tag
                      v-if="item.onTop"
                      round
                      type="danger"
                      class="ml-1"
                    >
                      置顶
                    </van-tag>
                  </div>
                  <div class="text-xs text-gray-400 flex flex-row justify-between items-center w-full">
                    <span><van-icon name="newspaper-o" /> {{ item.category }}</span>

                    <span><van-icon name="calendar-o" /> {{
                      $dayjs(item.publishedAt).format('YY年MM月DD日')
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </template>
      <van-empty
        v-else
        description="暂无社区新闻"
      />
    </template>
  </section>
</template>

<script>
import share from '@/mixins/share'
import { mapState } from 'vuex'
export default {
  name: 'PublicNews',
  mixins: [share],
  data() {
    return {
      from: null,
      loading: true,
      initializing: true,
      refreshing: false,
      form: {
        keyword: ''
      },
      news: {
        current_page: null,
        to: 0,
        total: 0,
        data: []
      }
    }
  },
  computed: {
    ...mapState('Common/Cloud', ['tcb'])
  },
  async mounted() {
    await Promise.all([this.fetch(), this.prepareWx()])
    let options = {
      title: '社区新闻列表',
      desc: '北京阳光丽景小区社区新闻列表',
    }
    this.updateShareData(options)
  },
  methods: {
    onRefresh() {
      this.refreshing = true
      this.fetch()
    },
    async fetch() {
      if (this.refreshing) {
        // eslint-disable-next-line no-console
        console.warn('这是在刷新页面')
        this.news = {
          current_page: 0,
          total: 0,
          to: 0,
          data: []
        }
        this.refreshing = false
      }
      let query =
        Object.keys(this.$route.query).length > 0
          ? this.$route.query
          : { keyword: '' }
      // eslint-disable-next-line no-console
      console.log('查询信息', query)
      this.form = query
      let page = (this.news.current_page ?? 0) + 1

      this.loading = true
      try {
        // eslint-disable-next-line no-console
        console.log('开始请求数据', page)
        const response = await this.tcb.callFunction({
          name: 'news',
          data: Object.assign(
            {
              $url: 'subscriberList',
              page: page
            },
            this.form
          )
        })
        // eslint-disable-next-line no-console
        console.log('函数请求返回', response)
        let { result } = response
        // eslint-disable-next-line no-console
        console.warn('总共有数据', page, response)
        let news = {
          data: this.news.data.concat(result.data.data),
          to: this.news.to + result.data.data.length,
          current_page: page,
          total: result.data.total
        }
        // eslint-disable-next-line no-console
        console.log('数据加载完毕', news)
        this.$set(this, 'news', news)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
        this.$notify({ type: 'danger', message: '暂时无法获取您的意见和建议' })
        this.news = {
          current_page: 0,
          total: 0,
          to: 0,
          data: []
        }
      }
      this.loading = false
      this.initializing = false
    },
    async doSearch() {
      this.initializing = true
      let query = Object.assign({}, this.form, {
        _timestamp: +new Date()
      })
      const next = await this.$router.push({ name: 'public.news', query })
      // eslint-disable-next-line no-console
      console.log('doSearch', next)
      this.fetch()
    },
    goPage(route) {
      this.$router.push(route)
    }
  }
}
</script>

<style lang="scss">
.list-card {
  .thumbnail {
    width: 80px;
    height: 80px;
    img {
      border-radius: 5px;
    }
  }
}
</style>
